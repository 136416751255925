<template>
  <div>
    <v-main>
      <v-container class="mt-3">
        <v-sheet class="mb-4 pa-4 rounded-xl">
          <div class="text-h6">
            {{ $t("tripInfo.details") }}
            <v-btn
              v-if="authTripUser?.isAdmin"
              :aria-label="$t('tripInfo.editTrip')"
              color="primary"
              icon
              :to="{ name: 'tripEdit', params: { tripId } }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
          <div>
            <v-icon left>mdi-map-marker</v-icon>
            <router-link
              v-if="trip.chosenPlace"
              :to="{
                name: 'tripPlace',
                params: { tripId: tripId, placeId: trip.chosenPlace.id },
              }"
              >{{ trip.chosenPlace.name }}
            </router-link>
            <span v-else>{{ $t(`placeTypes.${trip.placeType}`) }}</span>
          </div>
          <div>
            <v-icon left>mdi-calendar</v-icon>
            {{ date }}
          </div>
          <div>
            <v-icon left>mdi-clock-time-four-outline</v-icon>
            {{ time }}
          </div>
          <div v-if="trip.description" class="mt-2">
            {{ trip.description }}
          </div>
          <add-to-calendar-btn
            v-if="trip.chosenPlace"
            class="mt-1"
            :trip-id="tripId"
          />
        </v-sheet>
        <v-sheet class="mb-4 pa-4 rounded-xl">
          <div class="text-h6">{{ $t("tripInfo.participants") }}</div>
          <div class="d-flex align-center">
            <user-avatar-list :trip-id="tripId" />
            <router-link
              v-if="authTripUser?.joined"
              class="ml-2"
              :to="{ name: 'tripUsers', params: { tripId } }"
            >
              {{ $t("tripInfo.seeMore") }}
            </router-link>
          </div>
          <v-btn
            v-if="authTripUser?.isAdmin && !trip.chosenPlace"
            class="mt-1"
            color="primary"
            text
            :to="{ name: 'tripUserAdd', params: { tripId } }"
          >
            <v-icon left>mdi-account-plus</v-icon>
            {{ $t("tripInfo.addParticipants") }}
          </v-btn>
        </v-sheet>
        <v-sheet v-if="authTripUser?.joined" class="mb-4 pa-4 rounded-xl">
          <div class="text-h6">
            {{ $t("tripInfo.myDepartureAddress") }}
            <v-btn
              :aria-label="$t('tripInfo.changeDepartureAddress')"
              color="primary"
              icon
              :to="{
                name: 'tripUserEdit',
                params: { tripId, userId: authTripUser.userId },
              }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
          <v-chip class="mr-1" color="primary">
            <v-icon>
              {{ travelModes[authTripUser.travelMode].icon }}
            </v-icon>
          </v-chip>
          {{ authTripUser.address || $t("tripInfo.noDepartureAddress") }}
        </v-sheet>
      </v-container>
    </v-main>
    <v-footer v-if="!authTripUser?.joined" app>
      <v-container>
        <v-btn block class="my-3" color="primary" large rounded @click="join">
          {{ $t("tripInfo.join") }}
        </v-btn>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import AddToCalendarBtn from "@/components/buttons/AddToCalendarBtn.vue";
import UserAvatarList from "@/components/UserAvatarList.vue";

export default {
  components: {
    AddToCalendarBtn,
    UserAvatarList,
  },
  computed: {
    ...mapStores(useDeviceStore),
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .first();
    },
    date() {
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      return new Date(this.trip.arrivalDatetime).toLocaleString(
        this.deviceStore.language,
        options
      );
    },
    time() {
      const options = {
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(this.trip.arrivalDatetime).toLocaleString(
        this.deviceStore.language,
        options
      );
    },
    travelModes: getTravelModes,
    trip() {
      return this.$tripRepo.with("chosenPlace").find(this.tripId);
    },
    tripId() {
      return this.$route.params.tripId;
    },
  },
  methods: {
    async join() {
      const shareKey = this.$route.query.shareKey;
      await this.$tripRepo.join(this.trip.id, shareKey);
      this.$router.push({
        name: "tripUserEdit",
        params: { tripId: this.tripId, userId: this.$userRepo.authUser.id },
      });
    },
  },
};
</script>
