import { Repository } from "pinia-orm";
import { useLoadingStore } from "@/stores/loading";
import TripUser from "@/models/TripUser";
import TripUserService from "@/services/middler/TripUserService";

export default class TripUserRepository extends Repository {
  use = TripUser;
  service = new TripUserService();

  async update(tripUser) {
    const loadingStore = useLoadingStore();
    loadingStore["tripUser.update"] = true;
    const result = await this.service
      .partialUpdate(tripUser.id, tripUser)
      .finally(() => {
        loadingStore["tripUser.update"] = false;
      });
    this.save(result);
  }

  async remove(tripUser) {
    await this.service.delete(tripUser.id);
    this.destroy(tripUser.id);
  }
}
