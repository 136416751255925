var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"accept":"image/*","type":"file"},on:{"change":_vm.onFileChange}}),(!_vm.image)?_c('v-sheet',{staticClass:"d-flex justify-center rounded-xl",attrs:{"height":"300","width":"300"},on:{"click":_vm.onPlaceholderClick}},[_c('v-icon',{attrs:{"size":"180"}},[_vm._v("mdi-image-edit-outline")])],1):_vm._e(),(_vm.image)?_c('div',{staticClass:"cropper-container"},[_c('cropper',{attrs:{"image-restriction":"stencil","resize-image":{
        adjustStencil: false,
      },"src":_vm.image,"stencil-component":"circle-stencil","stencil-props":{
        handlers: {},
        movable: false,
        resizable: false,
        aspectRatio: 1,
      },"stencil-size":{
        width: 300,
        height: 300,
      }},on:{"change":_vm.change}}),(_vm.image)?_c('v-btn',{staticClass:"close-btn",attrs:{"fab":"","x-small":""},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }