import { useConfigStore } from "@/stores/config";
import { useRepo } from "pinia-orm";
import MessageService from "@/services/middler/MessageService";
import TripRepository from "@/repositories/TripRepository";

const messageService = new MessageService();
const tripRepo = useRepo(TripRepository);

async function getUnreadMessageCount() {
  const results = await messageService.unreadCount();
  let totalUnreadMessages = 0;
  const trips = [];
  for (const result of results) {
    trips.push({
      id: result.tripId,
      unreadMessageCount: result.count,
    });
    totalUnreadMessages += result.count;
  }
  const configStore = useConfigStore();
  configStore.setTotalUnreadMessages(totalUnreadMessages);
  tripRepo.save(trips);
}

export { getUnreadMessageCount };
