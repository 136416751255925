<template>
  <v-menu v-if="notificationStore.isEnabled" offset-y>
    <template #activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="toggleNotifications">
        <v-list-item-title>
          {{
            mutedTrip
              ? $t("tripDetailMenu.unmuteNotifications")
              : $t("tripDetailMenu.muteNotifications")
          }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapStores } from "pinia";
import { useNotificationStore } from "@/stores/notification";
export default {
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapStores(useNotificationStore),
    mutedTrip() {
      return this.$mutedTripRepo.where("tripId", this.tripId).first();
    },
  },
  methods: {
    toggleNotifications() {
      if (this.mutedTrip) {
        this.$mutedTripRepo.remove(this.mutedTrip);
      } else {
        this.$mutedTripRepo.create(this.tripId);
      }
    },
  },
};
</script>
