import { Badge } from "@capawesome/capacitor-badge";
import { defineStore } from "pinia";

export const useConfigStore = defineStore("config", {
  state: () => {
    return {
      placeViewName: "tripMap",
      totalUnreadMessages: 0,
    };
  },
  actions: {
    async setTotalUnreadMessages(count) {
      this.totalUnreadMessages = count;
      const result = await Badge.isSupported();
      if (!result.isSupported) return;
      Badge.set({ count });
    },
  },
});
