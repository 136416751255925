<template>
  <div>
    <v-snackbar top :value="status === 'sent'">
      {{ $t("userSettingsHelp.sent") }}
    </v-snackbar>
    <v-snackbar color="error" top :value="status === 'error'">
      {{ $t("userSettingsHelp.error") }}
    </v-snackbar>
    <v-app-bar app color="transparent" flat>
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title class="mr-8">
        {{ $t("userSettingsHelp.title") }}
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <validation-observer
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container class="mt-4">
          <div class="mb-5">
            {{ $t("userSettingsHelp.description") }}
          </div>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userSettingsHelp.fromEmailField.name')"
            rules="required|email"
            vid="fromEmail"
          >
            <v-text-field
              v-model="fromEmail"
              autocomplete="email"
              :error-messages="errors"
              :label="$t('userSettingsHelp.fromEmailField.label')"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userSettingsHelp.messageField.name')"
            rules="required"
            vid="message"
          >
            <v-textarea
              v-model="message"
              :error-messages="errors"
              :label="$t('userSettingsHelp.messageField.label')"
            />
          </validation-provider>
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="!invalid" app>
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              :disabled="status !== 'idle'"
              large
              rounded
              type="submit"
            >
              {{ $t("userSettingsHelp.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import EmailService from "@/services/middler/EmailService";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";

export default {
  components: {
    GoBackBtn,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    subject: "Help and feedback",
    message: "",
    fromEmail: "",
    status: "idle",
  }),
  methods: {
    async onSubmit() {
      this.status = "sending";
      const emailService = new EmailService();
      try {
        await emailService.sendToTeam(
          this.subject,
          this.message,
          this.fromEmail
        );
        this.status = "sent";
        setTimeout(() => {
          this.$router.push({ name: "userSettings" });
        }, 3000);
      } catch (error) {
        this.status = "error";
        setTimeout(() => {
          this.status = "idle";
        }, 3000);
      }
    },
  },
};
</script>
