<template>
  <div>
    <v-app-bar app color="transparent" flat>
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title class="mr-8">
        {{ $t("userSettingsAvatar.title") }}
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <form @submit.prevent="onSubmit">
      <v-main>
        <v-container class="d-flex justify-center">
          <image-cropper :url="user.avatar" @change="onChange" />
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="hasChanged" app>
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              :disabled="loadingStore['user.update'] || isCropping"
              large
              :loading="loadingStore['user.update'] || isCropping"
              rounded
              type="submit"
            >
              {{ $t("userSettingsAvatar.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </form>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import { useRepo } from "pinia-orm";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import ImageCropper from "@/components/inputs/ImageCropper.vue";
import UserRepository from "@/repositories/UserRepository";
import _clone from "lodash/clone";

export default {
  components: {
    GoBackBtn,
    ImageCropper,
  },
  data: () => {
    const userRepo = useRepo(UserRepository);
    const user = _clone(userRepo.authUser);
    return {
      canvas: null,
      hasChanged: false,
      isCropping: false,
      user,
    };
  },
  computed: {
    ...mapStores(useLoadingStore),
  },
  methods: {
    onChange(event) {
      this.canvas = event ? event.canvas : null;
      this.hasChanged = true;
    },
    async onSubmit() {
      let file = null;
      if (this.canvas) {
        this.isCropping = true;
        let blob = {
          size: null,
        };
        let quality = 1;
        const maxSize = 512 * 1024; // 512 KB
        while (!blob.size || blob.size > maxSize) {
          blob = await new Promise((resolve) =>
            this.canvas.toBlob(resolve, "image/webp", quality)
          );
          quality -= 0.2;
        }
        file = new File([blob], "avatar.webp", { type: blob.type });
        this.isCropping = false;
      }
      await this.$userRepo.update(this.user, file);
      this.$router.push({ name: "userSettings" });
    },
  },
};
</script>
