import { Model } from "pinia-orm";
import Poll from "@/models/Poll";
import Trip from "@/models/Trip";
import TripPlace from "@/models/TripPlace";
import TripUser from "@/models/TripUser";
import TripUserPlace from "@/models/TripUserPlace";
import TripadvisorImage from "@/models/TripadvisorImage";

export default class Place extends Model {
  static entity = "places";
  static fields() {
    return {
      id: this.string(null),
      name: this.string(""),
      address: this.string(""),
      lat: this.number(null),
      lng: this.number(null),
      priceRange: this.string(null),
      cuisines: this.attr([]),
      tripadvisorImages: this.hasMany(TripadvisorImage, "placeId").onDelete(
        "cascade"
      ),
      tripadvisorReviewUrl: this.string(null),
      stationVehicleModes: this.attr([]),
      trips: this.belongsToMany(Trip, TripPlace, "placeId", "tripId"),
      tripUsers: this.belongsToMany(
        TripUser,
        TripUserPlace,
        "placeId",
        "tripUserId"
      ),
      polls: this.hasMany(Poll, "placeId"),
      isSearchResult: this.boolean(false),
    };
  }
}
