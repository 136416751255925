<template>
  <div style="background-color: var(--v-primary-base); height: 100%">
    <v-app-bar app color="primary" dark flat>
      <go-back-btn :query="{ redirect: $route.query.redirect }" />
    </v-app-bar>
    <validation-observer
      ref="form"
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container class="white--text">
          <div class="mb-5 text-h5">{{ $t("userLogin.title") }}</div>
          <v-scroll-y-transition>
            <v-alert v-if="formErrorMessage" dense type="error">
              {{ formErrorMessage }}
            </v-alert>
          </v-scroll-y-transition>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userLogin.phoneNumber')"
            rules="required|phoneNumber"
            vid="phoneNumber"
          >
            <phone-number-input
              v-model="phoneNumber"
              dark
              :error-messages="showFieldErrors ? errors : []"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userLogin.password')"
            rules="required|min:10"
            vid="password"
          >
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              autocomplete="current-password"
              dark
              :error-messages="showFieldErrors ? errors : []"
              :label="$t('userLogin.password')"
              required
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </validation-provider>
          <div style="text-align: right">
            <router-link
              class="white--text"
              :to="{
                name: 'userResetPasswordStep1',
                query: { phoneNumber, redirect: $route.query.redirect },
              }"
            >
              {{ $t("userLogin.forgotPassword") }}
            </router-link>
          </div>
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="!invalid" app color="primary">
          <v-container>
            <v-btn
              block
              class="my-3"
              dark
              :disabled="loadingStore['user.login']"
              large
              :loading="loadingStore['user.login']"
              outlined
              rounded
              type="submit"
            >
              {{ $t("userLogin.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { MiddlerAPIError } from "@/services/middler/errors.js";
import { NavigationFailureType, isNavigationFailure } from "vue-router";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import { useLoadingStore } from "@/stores/loading";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import PhoneNumberInput from "@/components/inputs/PhoneNumberInput.vue";
import websocket from "@/websocket";

export default {
  components: {
    GoBackBtn,
    PhoneNumberInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      formErrorMessage: null,
      password: "",
      phoneNumber: this.$route.query.phoneNumber || "",
      showFieldErrors: false,
      showPassword: false,
    };
  },
  computed: {
    ...mapStores(useDeviceStore, useLoadingStore),
  },
  methods: {
    redirect() {
      this.$router
        .push(
          this.$route.query.redirect
            ? {
                path: this.$route.query.redirect,
              }
            : { name: "tripList" }
        )
        .catch((failure) => {
          // If the navigation was cancelled, do nothing. Happens if an unauthenticated user tries
          // to access a protected route.
          if (!isNavigationFailure(failure, NavigationFailureType.cancelled)) {
            throw failure;
          }
        });
    },
    async onSubmit() {
      this.showFieldErrors = true;
      const loggedIn = await this.login();
      if (loggedIn) {
        await this.deviceStore.fetchOrCreate();
        websocket.reconnect();
        this.redirect();
      }
    },
    async login() {
      try {
        await this.$userRepo.login(this.phoneNumber, this.password);
        return true;
      } catch (error) {
        if (error instanceof MiddlerAPIError) {
          // Set errors for each field
          this.$refs.form.setErrors(error.data);
          // Set error message for the form
          this.formErrorMessage = error.data.detail;
          setTimeout(() => {
            this.formErrorMessage = null;
          }, 3000);
          return false;
        } else {
          throw error;
        }
      }
    },
  },
};
</script>
