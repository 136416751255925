<template>
  <v-card class="rounded-xl" v-on="$listeners">
    <v-card-title v-if="trip.name">
      <place-type-icon left :type="trip.placeType" />
      {{ trip.name }}
    </v-card-title>
    <v-card-subtitle class="d-flex">
      <place-type-icon v-if="!trip.name" left :type="trip.placeType" />
      <time :arrivalDatetime="trip.arrivalDatetime">
        {{ formatDatetime(trip.arrivalDatetime) }}
      </time>
      <v-spacer />
      <v-badge
        v-if="trip.unreadMessageCount"
        bordered
        :content="trip.unreadMessageCount"
        overlap
      >
        <v-icon>mdi-chat</v-icon>
      </v-badge>
    </v-card-subtitle>
    <v-card-text class="d-flex justify-space-between">
      <user-avatar-list :trip-id="trip.id" />
      <v-icon v-if="isMuted">mdi-bell-off</v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import PlaceTypeIcon from "@/components/PlaceTypeIcon.vue";
import UserAvatarList from "@/components/UserAvatarList.vue";

export default {
  components: {
    PlaceTypeIcon,
    UserAvatarList,
  },
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapStores(useDeviceStore),
    isMuted() {
      return this.$mutedTripRepo.where("tripId", this.tripId).first();
    },
    trip() {
      return this.$tripRepo.with("chosenPlace").find(this.tripId);
    },
  },
  methods: {
    formatDatetime(isoString) {
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(isoString).toLocaleString(
        this.deviceStore.language,
        options
      );
    },
  },
};
</script>
