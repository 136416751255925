<template>
  <div>
    <input
      ref="fileInput"
      accept="image/*"
      style="display: none"
      type="file"
      @change="onFileChange"
    />
    <v-sheet
      v-if="!image"
      class="d-flex justify-center rounded-xl"
      height="300"
      width="300"
      @click="onPlaceholderClick"
    >
      <v-icon size="180">mdi-image-edit-outline</v-icon>
    </v-sheet>
    <div v-if="image" class="cropper-container">
      <cropper
        image-restriction="stencil"
        :resize-image="{
          adjustStencil: false,
        }"
        :src="image"
        stencil-component="circle-stencil"
        :stencil-props="{
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: 1,
        }"
        :stencil-size="{
          width: 300,
          height: 300,
        }"
        @change="change"
      />
      <v-btn v-if="image" class="close-btn" fab x-small @click="remove">
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
import { Camera, CameraResultType } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { Cropper } from "vue-advanced-cropper";

export default {
  components: {
    Cropper,
  },
  props: {
    url: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  data: () => {
    return {
      hasChanged: false,
      image: null,
    };
  },
  mounted() {
    this.image = this.url;
  },
  destroyed() {
    if (this.image) {
      URL.revokeObjectURL(this.image);
    }
  },
  methods: {
    change(event) {
      if (!this.hasChanged) {
        this.hasChanged = true;
      } else {
        this.$emit("change", event);
      }
    },
    onFileChange({ target }) {
      const { files } = target;
      if (this.image) {
        URL.revokeObjectURL(this.image);
      }
      const objectURL = URL.createObjectURL(files[0]);
      this.image = objectURL;
    },
    remove() {
      this.hasChanged = true;
      this.image = null;
      this.$emit("change", null);
    },
    async onPlaceholderClick() {
      this.hasChanged = true;
      if (Capacitor.isNativePlatform()) {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
        });
        this.image = photo.dataUrl;
      } else {
        this.$refs.fileInput.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 0.8rem;
  position: absolute !important;
  top: 0px;
}
.cropper-container {
  height: 300px;
  width: 300px;
  position: relative;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
